export default {
  // Общие строки для всех разделов
  common: {
    menu_title: 'Menu',
    menu: {
      notifications: 'Notifications',
      wall: 'Wall',
      address: 'Address book',
      profile: 'Personal area',
      groups: 'Groups',
      calendar: 'Events',
      knowl_base: 'Knowledge base',
      vacancies: 'Vacancies',
      services: 'Services',
      polls: 'Polls',
      chat: 'Chats',
      structures: 'Structures',
      no_silent: 'Don\'t be quite',
      ideas: 'Get idea',
      employees: 'Administration',
      clients: 'Clients',
      admin: 'Admin panel',
      shop: 'Shop',
      task_tracker: 'Task tracker',
      adaptation: 'Adaptation',
      vacations: 'Vacations',
    },
    error: 'Error',
    errors: {
      common: 'An error occured',
      file_upload: 'Uploading error',
      file_dwn: 'Downloading error',
      file_save: 'File save error',
      no_bucket: 'No bucket with name',
      file_size: 'Error: file size too big',
      file_del: 'Error while file deleting',
      files_del: 'Error while files deleting',
      file_size2: 'Files above {size} cannot be attached',
      user_not_found: 'User not found',
      file_not_found: 'The file was not found or was deleted',
      user_change: 'Error while changing the user',
      sending: 'Error while sending',
      fill: 'Error while data filling',
      required: 'Field is required',
      data: 'Errors with data',
      data_full: 'Errors while data validation',
      no_val: 'No value',
      title: 'Title is empty',
      no_access: 'No access',
      img_size: 'Wrong image size',
      empty_fields: 'Some data fields are empty',
      bucket_limit: 'Storage limit reached ({size})! Available {available}',
      no_komment_owner_kind: 'No comment feed owner type specified',
    },
    dates: {
      today: 'Today',
      yesterday: 'Yesterday',
      tomorrow: 'Tomorrow',
      in: 'In',
      ago: 'ago',
      until: 'Until',
      years: "0 years | {count} year | {count} years",
      months: "0 months | {count} month | {count} months",
      weeks: "0 weeks | {count} week | {count} weeks | {count} weeks",
      days: "0 days | {count} day | {count} days",
      hours: '0 hours | {count} hour | {count} hours',
      minutes: '0 minutes | {count} minute | {count} minutes',
      seconds: '0 seconds | {count} second | {count} seconds',
      less_than_month: 'less than month',
    },
    dates_f: {
      years: "0 | year | years ",
      months: "0 | month | months",
      days: "0 | day | day",
      weeks: "0 | week | week",
      hours: '0 | hour | hours',
      minutes: '0 | minute | minutes',
      seconds: '0 | second | seconds',
    },
    time: {
      M: 'mon',
      d: 'd',
      h: 'h',
      m: 'min',
      s: 'sec',
    },
    time_f: {
      M: 'months',
      d: 'days',
      h: 'hours',
      m: 'minutes',
      s: 'seconds',
    },
    begin: 'Start',
    end: 'End',
    select: 'Select',
    select_many: 'Select many',
    delete: "Delete",
    edit: "Edit",
    restore: 'Restore',
    add: 'Add',
    create: 'Create',
    change: 'Change',
    update: 'Update',
    save: 'Save',
    rename: 'Rename',
    replace: 'Move to',
    send: 'Send',
    edit_rights: 'Set access rights',
    apply: 'Apply',
    download: 'Download',
    export: 'Export',
    cancel: 'Cancel',
    canceling: 'Cancel',
    yes: 'Yes',
    no: 'No',
    good: 'Good',
    turn_on: 'Turn on',
    turn_off: 'Turn off',
    back: 'Back',
    file_loading: 'File loading',
    file_deleted: 'File deleted',
    files_deleted: 'All files deleted',
    find: 'Find',
    flush: 'Clear',
    open: 'Open',
    close: 'Close',
    show: 'Show',
    load: 'Load',
    empty_m: 'Not specified',
    empty_f: 'Not specified',
    confirm: 'Confirm',
    i_confirm: 'I confirm',
    extra_info: 'Extra information',
    link: 'Link',
    title: 'Title',
    comment: 'Commentary',
    description: 'Description',
    save_changes: 'Save changes',
    saved: 'Saved',
    delete_all: 'Delete all',
    show_all: 'Show all',
    expand: 'Expand',
    collapse: 'Collapse',
    attach: 'Add files',
    uploader_text: 'Attach and drag files here',
    uploader_info: 'Any file types up to 100 MB',
    file_too_big: 'File size is too big',
    file_up: 'File uploaded',
    file_uploaded_succ: '{file} uploaded successfully',
    file_upload_fail: '{file} not uploaded',
    adjust_image: 'Upload and adjust the image',
    enter_text: 'Enter text',
    fields_not_filled: 'Fields are empty',
    recievers: 'Receivers',
    data_sent: 'Data was sent',
    data_upd: 'Data was updated',
    data_ch: 'Data was changed successfully',
    data_saved: 'Data was saved successfully',
    mails_sent_to_users: 'E-mails was sent to selected users',
    people: 'persons',
    sel_many: 'Selected',
    members_pl: 'no one | member | members | members',
    pages: 'Total {total} pages',
    confirm_del: 'Do you confirm deleting?',
    confirm_cancel: 'Do you confirm canceling?',
    insert_url: 'Paste a link',
    not_found: 'Not found',
    empty_here: 'Nothing here',
    unsaved_files: 'Unsaved files',
    ph: {
      title: 'Enter title',
      date: 'Enter date',
      num: 'Enter a number',
      str: 'Enter string',
      url: 'Enter URL',
    },
    posts: {
      title: 'Create post',
      wall_company_news: 'Company news',
      thanking: 'Post to thank somemone',
      thanking_hint: 'Write at least 30 symbols and surely <br> mention an employee who you want to thank',
      ext_editor: 'Extended editor',
      send_hint: 'Sending: Ctrl+ENTER, line beaking: Shift+ENTER',
      append_file: 'Append file',
      append_files: 'Append files',
      file_size_hint: 'File size cannot be more than 100 MB',
      attachments: 'Attachments',
      media: 'Images and video',
      documents: 'Documents and other files',
      ph_thanking: 'Mention a person for thanking',
      ph_text: 'Write message text. Type @ to choose mentioned person',
      message: 'Message',
      send: 'Send',
      like: 'Like',
      dislike: 'Dislike',
      comments: 'Comments',
      comment: 'Comment | {n} comment | {n} comments | {n} comments',
      add_comment: 'Add comment',
      show_all: 'Show all comments',
      show_more: 'No comments more | Show more {n} comment | Show more {n} comments',
      answer: 'Answer',
      more_act: 'Actions',
      hide: 'Hide',
      show: 'Show {len} answers',
      created: 'Post was created:',
      deleted: 'Post was deleted',
      more: 'More',
      share: 'Share',
      copy: 'Copy link',
      link_copied: 'Link is copied',
      link_not_copied: 'Link is not copied',
      post_actions: 'Actions with post',
      comment_actions: 'Actions on comment',
      mentioned_empls: 'Mentioned employees: ',
      confirm_comment_del: "Do you confirm deletion of the comment?",      
      comment_deleted: 'Comment was deleted',
      input_more30: 'Input more than 30 symbols',
      choose_person_to_thank: 'Mention one person who will be thanked in the post',
      system_msg: 'System notification',
      edited: 'Edited',
      and_yet: 'and more',
      show_comments: 'Expand comments ({len} more)',
      no_comments: 'Hide comments',
      hide_comments: 'Collapse out comments',
      edit_post: 'Edit the post',
      editing_post: 'Editing the post',
      edit_comment: 'Edit the comment',
      ch_files: 'Change files',
      text_err: 'No message text',
      err_size: 'Files more 20 MB cannot be attached to posts',
      load_more: 'Load more...',
      back_to: {
        user: 'Back to user blog',
        feed: 'Back to feed',
        company: 'Back to company wall',
        group: 'Back to the group',
      },
      emoji: {
        search: "Search",
        notfound: "Smiles not found",
        categories: {
          search: " Search results",
          recent: "Frequently used",
          smileys: "Smiles",
          people: "People",
          nature: "Animals and nature",
          foods: "Food and drink",
          activity: "Activities",
          places: "Travels",
          objects: "Buildings",
          symbols: "Symbols",
          flags: "Flags",
          custom: "Custom"
        }
      },
      mentions_no_data: 'Enter the user\'s first name or last name'
    },
    list: {
      find_by_fullname: 'Find by full name',
      no_position: 'no position',
      no_selected: 'Nobody is selected',
    },
    login_bar: {
      show_msg_in_console: 'Show messages in console:',
      logout: 'Logout',
    },
    notifs: {
      group: {
        new: 'New group was created',
      },
      empl: {
        new: 'New employee was created'
      },
      feed: {
        need_update: 'Need to update feed',
      },
      post: {
        blog_new: 'New post in blog',
        blog_del: 'Post in blog was deleted',
        blog_edited: 'Post in blog was edited',
        company_new: 'Company news was published',
        company_edited: 'Company news was edited',
        feed_new: 'New post in your feed',
        feed_edited: 'Post in your feed was edited',
        group_new: 'New post in group',
        group_del: 'Post in group was deleted',
        group_edited: 'Post in group was edited',
        descr_news: 'Check company feed',
        descr_feed: 'Check your feed',
        descr_blog: 'Check employee blog ',
        descr_group: 'Check group ',
      },
      data_copied: 'Data copied',
    },
    mood: {
      title: 'How are you feeling?',
      rate: 'Rate your mood when you have came to the office, from 1 to 10',
      thanks: 'Thank you! Data was recorded!',
      filename: 'Mood of employees at {name} for period {begin}-{end}',
      excel_sheet: 'Mood of employees',
      err_excel: 'Error during export data to Excel'
    },
    tags: {
      title: 'Tags',
      add: 'Add tag',
      new: 'New tag',
      create: 'Create a new label',
    },
    e404: 'Oops, it seems this page is no longer relevant. Perhaps its address has changed or the object does not exist',
    to_main_page: 'Go to the main page',
    no_recipient_yet: 'So far, none of the employees have been appointed...',
    one_select: 'Select a value',
    select_date: 'Select date',
    list_select: 'Select from list',
    empl_select: 'Select employees',
    loading: 'Loading...',
    code_copied: 'Code was copied',
    footer: {
      tech_support: 'Tech support',
    },
    cover_size: 'Upload a cover with a width of 3420px and a height of at least 250px in PNG or JPG format'
  },
  login: {
    title: 'Enter',
    ws: 'Workspace',
    copy: 'All rights reserved',
    site: 'Main site',
    policy: 'Privacy Policy',
    terms: 'Terms of use',
    agree: 'I accept',
    required: 'This field is required',
    no_email: 'User with such e-mail doesn\'t exist',
    pwd: 'Password',
    pwd2: 'Confirm password',
    pwd_err: 'Password is incorrect',
    auth: 'Log in',
    agreement1: 'Entering to the portal you agree with',
    agreement2: 'User Agreement',
    pers_agreement: 'and you agree',
    pers_agr: 'to process personal data',
    forgot: 'Forgot password?',
    lang: 'Choose language',
    err: {
      enter: 'Error of login',
      no_login_pwd: 'Login or password was not specified',
      user_del: 'User was deleted',
      client_blocked: 'The company is blocked',
      server: 'Error on server side',
      data: 'Validation error',
      restore: 'Error of restoring password',
      pwd_change: 'Error changing password',
      pwd: 'Please enter a new password',
      pwd2: 'Please enter a new password again',
      data_send: 'Error sending data',
      pwd_equal: 'Make sure the passwords match',
    },
    restore_title: 'Restore password',
    restore_sub: 'We will send link for restoring to your e-mail',
    restore_ok: 'Check your e-mail and follow a link to set new password',
    letter_sent: 'The letter was sent',
    back: 'Go back',
    reset: 'Password reset',
    reset_ok: 'Password was changed',
    reset_msg: 'New password was set'
  },
  // таблицы
  tbl: {
    // колонки
    cols: {
      // общие для многих таблиц
      common: {
        name: 'Name',
        descr: 'Description',
        date: 'Date',
        time: 'Time',
      },
      admin: {

      },
    }
  },
  grant_access: {
    title: 'Grant access',
    btn: 'Select rights',
    all: 'All employees',
    all_heads: 'All leaders',
    read_level: 'Read',
    write_level: 'Write',
    no_level: 'None',
    all: 'Available to all',
    all_mngrs: 'All managers',
    types: {
      for_all: {
        title: 'Available to everyone',
        desc: 'All employees of the company'
      },
      search_by_head: {
        title: 'Search by heads',
        desc: 'Only department heads',
        search_placeholder: 'Enter name'
      },
      search_by_name: {
        title: 'Search by name and position',
        desc: 'If you want to add someone in particular',
        search_placeholder: 'Enter name, position'
      },
      search_by_department: {
        title: 'By department',
        desc: 'Access will be available to all employees of departments',
        search_placeholder: 'Enter department name'
      },
      search_by_city: {
        title: 'By city',
        desc: 'Select a specific branch from the structure',
        search_placeholder: 'Enter city name',
      },
      search_by_time: {
        title: 'By working time',
        desc: 'Sort by opening hours',
        search_placeholder: 'Enter time',
      },
      manual_search: {
        title: 'Manual filtering',
        desc: 'Choose your filters',
        search_placeholder: 'Enter filter',
      },
    },
    save: 'Give access',
    set_ok: 'Permissions set successfully',
    error: 'Grants error',
    depts: 'Departments',
    nothing_selected: 'Nothing selected, some permissions need to be selected',
  },
  // Стена компании
  company: {
    feed: {
      company_feed: 'Company news',
      thank: 'Thankfullness',
      user_feed: 'My news feed',
      type_unknown: 'Unknown event type',
      tooltip_user: 'Posts in groups and blogs that you subscribe to. Notifications about birthdays, invitations to events and other news',
      tooltip_company: 'Only important company news',
      bd_today: 'Celebrating a birthday today'
    },
    mention: {
      in: 'Mentioned in {title}',
      group: 'Mention in a group',
      blog: 'Mention in a blog',
      comment_answer_of: 'Mention in answer on comment to',
      post_in: 'post in',
      del_post: 'deleted post',
      comment_news: 'Mention in comment to news in the company feed',
      other: 'Other mention',
      text: 'Text of message'
    },
    comments: {
      i_new_empl: 'I am new employee',
      blog_post: 'Comment to post in the blog',
      feed_post: 'Comment to post in the feed',
      group_post: 'Comment to post in the group',
      blog_answer: 'Answer to your comment in the blog',
      type_not_defined: 'Type of comment is not recognized'
    },
    title: {
      news_comment: 'comment to news',
      post_comment: 'comment to post',
      news: 'news of the company',
      group_comment: 'comment in the group',
      blog_comment: 'comment in the blog',
      feed_post: 'post on company wall',
      feed_post_comment: 'comment to the post on company wall',
    },
    places: {
      your_blog: 'in your blog',
      post_del: 'deleted post',
      news: 'company news feed',
      user_blog: 'in the blog of {name}',
      group: 'in group {name}',
      feed: 'company feed',
    },
    msg: {
      group_new: 'have created new group',
      group_del: 'have deleted group',
      group_edit: 'have changed group data',
      title: {
        new_group: 'New group',
        del_group: 'Group was deleted',
        new_empl: 'New employee'
      }
    },
    notifs: {
      new_group: 'Created a new group',
      del_group: 'Deleted the group',
      new_empl: 'We welcome a new employee',
      add_post: ' added a post ',
      post_in_blog: 'in his/her blog',
      post_in_group: 'in the group',
      post_in_feed: 'in the feed',
    },
    widgets: {
      thanks: {
        title: 'Thanks',
        descr: 'Employees who received the most thanks',
        no: 'Thanks have not been left yet. You can tag a colleague in a thank you post',
      },
      bdays: {
        title: 'Birthdays',
        today: 'Today!',
        today_more: '{n} more today',
        month_more: '{n} more in this month',
        descr: 'Employees celebrating a birthday today or in the coming dates',
        no: 'No one is celebrating a birthday today',
        congr: 'Congratulate',
        congrats: 'Happy birthday to you, @{name}! 🥳🎁🎈',
        next: 'All birthdays',
      },
      groups: {
        title: 'Important in groups',
        descr: 'Posts or other important information in groups',
        no: 'So far there is nothing important in groups',
      },
      events: {
        title: 'Calendar',
        descr: 'Events or activities scheduled for today',
        no: 'There are no events in the calendar for today',
      },
      kb: {
        title: 'Important in the knowledge base',
        descr: 'Important articles or files in the knowledge base',
        no: 'There is no important information in the knowledge base yet',
      },
      polls: {
        title: 'Important in polls',
        descr: 'Important Opinion Surveys for Employees',
        no: 'There are currently no polls',
      },
      blogs:{
        title: 'Important in blogs',
        descr: 'Blog posts marked as important',
        no: 'There is no important information in the blogs at the moment',
      },
      vacancies: {
        title: 'Current Vacancies',
        descr: 'Jobs for the company that are currently open',
        no: 'There are no vacancies in the company yet',
      },
      other: {
        title: 'Important',
        descr: 'Other important information',
        no: 'No important information yet',
      },
    },
    welcome: 'welcome to our team!',
  },
  // Админская панель
  admin_panel: {
    title: 'Administrator\'s panel',
    tabs: {
      all_data: 'All data',
      mail: 'Checking mail',
      notif_settings: 'Settings of notifications',
      clean: 'Files cleaning',
      logs: 'Logs',
      service: 'Service actions'
    },
    tbl: {
      data_mng: {
        collection: 'Collection',
        docs_count: 'Rows count',
        actions: 'Actions',
      },
      posts: {
        _id: 'ID',
        message: 'Text',
        author_ref: 'Author',
        created: 'Date & time',
        parent: 'Parent',
      }, 
      users: {
        _id: 'ID',
        fullName: 'Full name',
        _email: 'E-mail',
        roles: 'Roles',
      },
      actions: {
        actions: 'Actions'
      },
      notifs: {
        fullName: 'Full name',
        addUser: 'Adding user',
        publications: 'Publications',
        mentions: 'Mentions',
        subscribe: 'Subscriptions',
        actions: 'Operations',
      },
      cleaning: {
        filename: 'File name',
        name: 'Path',
        lastModified: 'Updated at',
        size: 'Size',
      },
      logs: {
        date: 'Date/time',
        job_code: 'Event code',
        message: 'Message',
      },
    },
    tables: {
      users: 'Users',
      groups: 'Groups',
      posts: 'Posts',
      messages: 'Messages',
      events: 'Events',
    },
    done: 'Done processing',
    no_tbl_sel: 'Table not selected',
    client_data_mng: 'Manage client\'s data',
    test_data_notif: 'This page is used for test data, not for production clients!',
    del_data: 'Delete data',
    del_data_filter: 'Delete by filter',
    del_coll: 'Collection deleting ',
    del_confirm: 'The data will be erased completely, continue?',
    data_deleted: 'Data in collection was deleted: ',
    data_del_err: 'Error while deleting data',
    mail: {
      header: 'Test sending mail to boxes',
      test: 'Test of e-mail',
      not_sel: 'E-mail not selected',
      need_sel: 'You have to select at least one e-mail address',
      sent: 'Mail was sent',
      check: 'Check your e-mail',
      error: 'Sending was failed',
      sendsay_send: 'Test SendSay',
      template_send: 'Test html-tmpl',
    },
    cleaning: {
      check: 'Check files',
      del: 'Delete files',
      hint: 'Caution! Files will be checked and deleted for ALL clients, not only for current workspace',
      deleted: 'Deleted {deleted} files',
      error: 'Error while deleting files',
    },
    service: {
      polls_fix: 'Fix old polls',
      articles_fix: 'Set Permissions',
      articles_fixed: 'Permissions for articles (inc. folders, files) are set to Public',
      service_op: 'Perform operation',
    },
    jobs: {
      confirm_del: 'Delete background job?',
    }
  },
  // Адресная книга
  address: {
    title: 'Address book',
    title_R: 'of address book',
    no_empls: 'Employees not found',
    enter_search: 'Enter a search word',
    filter_by: 'Filter by column',
    empty: 'Right now your address book is empty, but contacts of your colleagues will appear in it soon',
    tbl: {
      empls: {
        name: 'Employee full name',
        positions: 'Position',
        phone: 'Phone',
        email: 'E-mail',
        departments: 'Department',
        cabinet: 'Office number',
        actions: 'Actions',
      },
    },
    create_chat: 'Open chat',
    pin_user: 'Pin employee',
    unpin_user: 'Unpin employee',
    pin_move_user: 'Move position',
    select_new: 'Select a new position',
  },
  // Профиль сотрудника
  profile: {
    dept_adm: 'Administrate dept.',
    dept_func: 'Functional dept.',
    dept_prj: 'Project dept.',
    depts: 'Employee departments',
    my_subscribers: 'My subscribers',
    subscribers: 'No subscribers | {count} subscriber | {count} subscribers',
    subscribe: 'Subscribe',
    subscribed: 'Subscribed yet',
    unsubscribe: 'Unsubscribe',
    new_subscribe: 'New subscriber',
    no_subscribers: 'There are no subscribers yet, look at all company employees in ',
    tabs: {
      main: 'Main',
      extra: 'Extra',
      soc_nets: 'Social networks',
      for_hr: 'Personnel info',
      files_hr: 'HR files',
      profile: 'Contacts',
      notifs: 'Notifications',
      subscribers: 'Subscribers',
    },
    blog: 'Blog',
    edit_empl: 'Edit employee',
    no_position: 'Position not filled',
    no_info: 'No info about person',
    edit_data: 'Edit profile',
    editing: 'Profile editing',
    set_status: 'Add status',
    add_status_title: 'Enter new status',
    status_ph: 'Enter status text',
    chat: 'Send message to chat',
    contacts: 'Contacts',
    int_phone: 'Internal phone',
    no_add_data: 'No additional data',
    manager: 'Manager',
    managers: 'Managers',
    no_manager: 'No department manager assigned',
    empls: 'Employees',
    colleagues: 'Colleagues',
    status_set: 'Status successfully set',
    open: 'Go to profile',
    birthday: 'Birthday: {date}',
    no_birthday: 'No birthday specified',
    working_in_company: 'In company',
    and_more: 'And {len} people more',
    no_location: 'Location not defined',
    load_pic: 'Load cover',
    del_pic: 'Delete cover',
    pic_change: 'Change profile photo',
    pic_updated: 'Photo changed',
    reset_to_hrm: 'Reset data',
    reset_hrm_confirm: 'Are you sure you want to reset your manual entries?',
    reset_hint: 'Delete changed data and show data from ZUP',
    data_has_diffs: 'Data differs from those downloaded from 1C ZUP',
    reset_end_ok: 'Employee data has been returned to the data from 1C',
    email_copied: 'Email has been copied',
    genders: {
      man: 'Man',
      woman: 'Woman',
      not_selected: 'Not selected',
    },
    woman_decret: 'Employee on maternity leave',
    edit: {
      name: 'Name',
      surname: 'Surname',
      midname: 'Mid. name',
      position: 'Position',
      vac_days: 'Remainder of vacation',
      position_ph: 'Manager',
      empl_chief: 'Chief',
      cabinet: 'Cabinet',
      email: 'E-mail',
      phone: 'Phone number',
      lang: 'Language',
      change_ava: 'Change avatar',
      since: 'Working since',
      city: 'City',
      gender: 'Gender',
    },
    hints: {
      name: 'Enter name',
      surname: 'Enter surname',
      midname: 'Enter middle name',
      email: 'Enter your E-mail!',
      cabinet: 'Specify cabinet',
      position: 'Specify position',
      vac_days: 'Number of days',
      correct_email: 'Enter your email!',
      phone: 'Your phone number',
      lang: 'Select a language',
      gender: 'Select a gender',
    }
  },
  // Группы
  groups: {
    title: 'Groups',
    group: 'group',
    only_mine: 'Only my groups',
    feed: 'Feed',
    about: 'About group',
    create: 'Create group',
    ph_name: 'Book Club',
    ph_descr: 'We get together and discuss our favorite books and authors',
    join: 'Join',
    reject: 'Reject',
    accept: 'Accept',
    quit: 'Leave',
    approve: 'Approve',
    edit: 'Edit group',
    empty: 'You currently do not have any working groups. Create your own group or wait for an invitation from employees',
    no_access: 'Apply to join the group to watch and post',
    not_found: 'Groups not found',
    not_found_one: 'Group not found',
    deleted: 'Group was deleted',
    deleted_gr: 'Group {group} was deleted',
    deleting_from: 'Deleting from group',
    deleted_you: 'You was deleted from group «{name}»',
    deleted_other: 'Member {who} has left the group "{name}"',
    approving: 'Request approving',
    rejecting: 'Request rejecting',
    approved_you: 'Your request to group {name} has been approved',
    rejected_you: 'Your request to group {name} has been rejected',
    select_users: 'Select users',
    choose_new_owner: 'Select a new group owner',
    noempls_for_change: 'No employee for replace other',
    owner_changed: 'Group owner has been changed',
    make_admin: 'Add or remove administrator role',
    new_owner: 'New group owner',
    you_owner: 'You are admin of group',
    is_admin: 'Group Administrator',
    you_are_owner: 'You have became admin of the group {name}',
    owner_error: 'Error while setting group owner',
    only_1: 'There is only 1 member in the group',
    owner_change_cannot: 'It is impossible to change owner',
    added: 'added to the group',
    joined: ' joined the group ',
    actions: 'Actions with group',
    members: 'Members',
    q_delete: 'Remove {fio} from group?',
    confirm_deleting: 'Confirm deleting',
    confirm_deleting2: 'Confirm deleting the group',
    del_not_found: '<Group removed or not found>',
    invite: 'Invite',
    invite_empl: 'Invite user',
    invite_sent: 'Invite was sent',
    inviting: 'An invite',
    inviting_group: 'An invite to a group',
    you_invited: 'You have been invited to a group {name}. The link was sent to your e-mail',
    to_group: 'to group',
    set_owner: 'Set as owner',
    user_will_be_notified: 'User will get message in his feed',
    change_owner: 'Change owner',
    request: 'Request for membership of the group',
    requests: 'Requests',
    req_created: 'Request for join was created',
    req_removed: 'Request was removed',
    request_exists: 'Request was created or you are in the group',
    make_request: 'Make request',
    cancel_request: 'Cancel request',
    request_rejected: 'Request was rejected',
    request_rejected_descr: 'Accession to the group was rejected',
    rejected: 'Group invitation declined',
    rejected_full: '{user} declined your invitation to the group {group}',
    type: 'Type',
    type_public: 'Public',
    type_closed: 'Closed',
    type_private: 'Private',
    type_public_descr: 'Any user can find a group through search or in the general list, view publications and join the community',
    type_closed_descr: 'Any user can find a group through search or in the general list. You can join and view publications only by invitation or after approval of the application by the group administrator',
    type_private_descr: 'Only its members can find a group in the search or in the general list. You can only join by invitation from the group administrator.',
    request_made: 'Request was created',
    request_approved: 'Request was approved',
    request_approved_descr: 'Accession to the group was approved',
    request_made_descr: 'Request for membership was created',
    made_request: 'made request for membership to the group',
    you_have_invite: 'You have an invite to this group. Select action:',
    you_in: 'You has became a member of the group',
    new_member: 'A new member of the group',
    new_member_full: 'In the group {name} a new member: {user}',
    posted_in: 'added a post in group ',
    rules: {
      name: 'Name cannot be blank!',
      name_max: 'Maximum length of name is 50 symbols',
      descr: 'Description cannot be blank!',
      descr_max: 'Maximum length of description is 250 symbols',
      type: 'Group type cannot be blank!'
    },
    errors: {
      creating: 'Error of group creation', 
      client: 'Error of client checking',
      client_descr: 'Could not specify current client',
      data_descr: 'Some fields filled incorrect',
      valid: 'Validation error',
      request: 'Error while accepting request',
      reject: 'Error while rejecting request',
    },
    notifs: {
      new_user: 'New member in group {group}: {userFio}',
      no_access: 'You have no access to group {name}'
    }
  },
  // Календарь
  cal: {
    title: 'Scheduler',
    year: 'Year',
    month: 'Month',
    week: 'Week',
    day: 'Day',
    wd: {
      mo: 'Mo',
      tu: 'Tu',
      we: 'We',
      th: 'Th',
      fr: 'Fr',
      sa: 'Sa',
      su: 'Su',
    },
    event: 'Event',
    create: 'Add event',
    new: 'New event',
    ph_name: 'Weekly meeting of top managers',
    moving: 'Event is moving',
    changing: 'Edit event',
    new_cal: 'New scheduler',
    not_found: 'The event not found',
    confirm_delete: 'Confirm event deleting',
    no_events_today: 'No events today',
    events_today: 'Events today',
    time_left: 'Time left',
    go_to_scheduler: 'Go to the scheduler',
    delete: 'Event cancel',
    del_one: 'Delete only this event',
    del_all: 'Delete all repeated event',
    del_select: 'Select kind of deleting',
    del_select_full: 'Select what will be deleted - one event or all repeated',
    del: 'The event was deleted successfully',
    deleted: 'Event <b>{name}</b> deleted',
    err_del: 'Error has occured while deleting event',
    cant_create: 'You cannot create events',
    err_date_lost: 'Event cannot be created in the past',
    new_user: 'Вітаємо нового співробітника компанії: ',
    time_changed: '<b>{name}</b> event time changed',
    f: {
      name: 'Event name',
      date: 'Event date',
      date2: 'Date of event:',
      start: 'Starts at:',
      end: 'Ends at:',
    },
    periods: {
      day: 'every day',
      week: 'every week in days',
      month: 'каждый every month',
      year: 'every year',
    },
    author_label: 'Event organizer',
    description: 'Description',
    date_time: 'Date and time of event',
    start_at: 'starts at',
    end_at: 'ends at',
    time_begins: 'Start time',
    time_finish: 'End time',
    event_changed: `Event <b>{name}</b> has been moved.<br/>
    Old time - from {fromBegin} to {fromEnd} ({fromDate})<br/>
    New time - from {toBegin} to {toEnd} ({toDate})`,
    color: 'Color',
    time_from: 'Time from',
    time_to: 'until',
    is_repeat: 'Recurring event',
    by_week: 'By week days: ',
    every: 'Every: ',
    end: 'Ends:',
    after: 'after',
    one_time: 'times',
    invite: 'Invite other employees',
    members: 'Event members',
    empls: 'Employees',
    zoom: 'Web conference link',
    you_are_participant: 'You was added to an event as participant',
    of_periodical: 'repeating',
    of_event: 'of event',
    members_pl: 'none | {count} member | {count} member | {count} participants',
    rules: {
      name: 'Name cannot be blank!',
      name_max: 'Max length of title is 100 symbols',
      name_too_short: 'Too short name, type more than 5 symbols',
      date: 'Date is required',
      date_past: 'The date cannot be in the past',
      time: 'Time is required',
      time_lost: 'Selected time have already passed',
      time_err: 'Incorrect ending time',
      members: 'Need to select participants',
      url: 'Incorrect URL',
      comment_max: 'Character limit exceeded',
      repeat: {
        no: 'Period of repeat is not selected',
        wdays: 'Week days for repeat is not specified',
        end: 'Date of ending repeat is not specified',
        count: 'Amount of event repeating is not specified',
        more1: 'Set more than one repeating number'
      }
    },
    notifs: {
      min_10: 'There is less than 10 minutes until event'
    },
    copy_link: 'Copy link',
    link_copied: 'Link to event has copied',
    link_not_copied: 'Error of copying event link',
    periods: {
      this_month: 'This month',
      last_month: 'Last month',
      this_year: 'This year',
      last_year: 'Last year',
      this_week: 'This week',
      last_week: 'Last week',
    },
  },
  // База знаний
  kb: {
    title: 'Knowledge base',
    articles: 'Materials',
    fav_articles: 'Favorites',
    folders: 'Sections',
    files: 'Files',
    amount: 'Atricles',
    no_art: 'There is nothing at now',
    empty: 'There are no documents in the knowledge base. Share your articles with colleagues or wait for the section to be updated',
    folder_empty: 'This folder is empty for now. Share your materials with colleagues or wait for the section to be updated.',
    new_article: 'New article',
    new_vacancy: 'New vacancy',
    new_folder: 'New folder in knowlege base',
    new_folder_sh: 'Add folder',
    new_file: 'Add file to knowlege base',
    new_file_sh: 'Add file',
    add_name: 'Add title',
    think_name: 'Think of a name',
    ph_descr: 'Tell what information can be found in the Knowledge Base',
    folder: 'Select folder',
    set_titles: 'Knowledge base headers settings',
    change_article: 'Change the article',
    change_folder: 'Change the folder',
    change_file: 'Change the file',
    created_at: 'Created at',
    updated_at: 'Updated at',
    search_ph: 'Search in knowlege base',
    rights: 'Rights',
    choose_file: 'Choose file',
    attach: 'Attach file',
    attached_files: 'Attached files',
    uploading: 'File is uploading...',
    drop_media: 'Attach image or video',
    add_cover: 'Add cover',
    del_cover: 'Remove сover',
    not_previewable: 'This kind of file is not previewable',
    show_all: 'Show all',
    hide_off: 'Show less',
    add: 'Add article',
    placeholder: 'White something here...',
    del: 'Delete article completely?',
    del_warn: 'Article will be deleted without posibility to repair',
    deleted: 'Article was deleted',
    saved: 'Article has been saved',
    folder_saved: 'Folder was saved',
    file_saved: 'File was saved',
    copy_link: 'Copy link',
    file_link_copied: 'Link to file was copied',
    correct: 'Fix articles',
    corrected: 'Articles were fixed',
    corrected_cnt: 'Updated {cnt} articles',
    author: 'Author',
    header: 'Title',
    toc_title: 'Table of contents',
    toc_empty: 'Start editing your document to see the outline',
    grants_article_title: 'Article permissions',
    grants_folder_title: 'Folder permissions',
    grants_file_title: 'File permissions',
    child: 'Child articles',
    no_preview: 'Preview unavailable',
    preview: 'File preview',
    pdf: 'Download as PDF',
    fav: 'Favorite',
    // to_fav: 'Add to favorites',
    // from_fav: 'From favorites',
    delete_file: 'Delete file?',
    delete_video: 'Delete uploaded video?',
    del_file_confirmation: 'Deleting file',
    del_folder_confirmation: 'Deleting folder',
    del_article_confirmation: 'Deleting article',
    del_file_deleting: 'File will be deleted completely. Continue?',
    del_folder_deleting: 'Folder and nested objects will be deleted completely. Continue?',
    del_article_deleting: 'Article will be deleted completely. Continue?',
    delete_files: 'Delete all files?',
    files_att: 'Attached files',
    filetype_hint: 'You can add docs of MS Office and .PSD, .CDR, .DWG files with size not bigger than 100 МB. Photos and videos can be inserted directly in article content',
    rights_title: 'Rights for reading',
    rights_del_hint1: 'Admin rights will be removed, continue?',
    rights_del_hint2: 'Rights of all employees will be removed, continue?',
    rights_not_set: 'Everyone can see',
    for_admins: 'For administrators',
    add_empls: 'Add employees',
    select_empls: 'Select employees who have rights for preview',
    author_not_found: 'Author not found',
    rights_succ: 'Rights was changed successfully',
    feedback_sent: 'Feedback was sent',
    user_will_get_mail: 'User will get the message as e-mail',
    change_folder: 'Change folder',
    move_folder: 'Move folder',
    no_title: 'No title',
    no_subtitle: 'No subtitle',
    mention_2symbols: 'White at least 2 characters',
    err: {
      no_title: 'Article header is empty!',
      no_content: 'Article content is empty!',
      no_service_group: 'Service group is empty!',
      no_section: 'Section is empty!',
      no_access: 'You have not access to this page',
      no_self_replace: 'You cannot move a folder into itself or into the current folder',
      file_not_media: 'You are trying to upload a file that is not a video or image',
      no_url: 'There is no link to the file',
    },
    new: {
      article: 'New article in knowledge base',
      service: 'New service',
      vacancy: 'UX-designer',
    },
    sort: {
      az: 'By name, A-Z',
      za: 'By name, Z-A',
      created: 'By creation date',
      edited: 'By last edited',
      no: 'Not sorted',
    },
    editor: {
      panel: {
        undo: 'Undo',
        redo: 'Redo',
        bold: 'Thick',
        italic: 'Italic',
        underline: 'Underline',
        strike: 'Strikethrough',
        code: 'Monospace',
        code_block: 'Code block',
        del_marks: 'Remove formatting',
        clear: 'Normal line',
        link: 'Insert link',
        del_link: 'Remove link',
        emoji: 'Emoji',
        media: 'Media file',
        music: 'Yandex.Music',
        toc: 'Table of contents',
        h1: 'Heading 1',
        h2: 'Heading 2',
        h3: 'Heading 3',
        h4: 'Heading 4',
        p: 'Plain text',
        ul: 'List',
        ol: 'Numbered list',
        quote: 'Quote',
        left: 'Left align',
        center: 'Center align',
        right: 'Right align',
        justify: 'Justify',
        marker: 'Background highlight',
        task: 'Task item',
        table: 'Insert table',
        t_add_column: 'Add column',
        t_add_column_after: 'Add column after',
        t_add_row: 'Add row',
        t_add_row_after: 'Add row after',
        t_del_col: 'Delete column',
        t_del_row: 'Delete row',
        t_delete: 'Delete table',
        t_cell_merge: 'Merge cells',
        t_cell_split: 'Split cell',
        t_header: 'Make first row header',
        t_col_header: 'Make column header',
      },
    },
  },
  // Вакансии
  vac: {
    title: 'Vacancies in the company',
    total: 'Total vacancies count',
    new: 'New vacancy',
    empty: 'There are currently no vacancies',
    no: 'There is no vacancies now',
    not_found: 'Vacancies not found',
    saved: 'Vacancy was saved',
    apply: 'Recommend/Apply',
    salary: 'Salary (from, to)',
    salary_from: 'from {val}',
    salary_up: ' to {val}',
    no_salary: 'Not specified',
    response: {
      recievers: 'Recipients of responses',
      recievers_no: 'No recipients specified',
      no_receivers_hint: 'Recipients of responses in the company are not specified',
      alert: 'Recipients are not specified, responses will be sent to all administrators',
      sent: 'Vacancy sent',
      no_user_to_receive: 'The response was not sent because no recipients were defined.'
    },
    deleting: 'Deleting vacancy',
    deleting_descr: 'Vacancy <b>{name}</b> will be deleted. Continue?',
    deleted: 'Vacancy has been deleted',
    deleted_succ: 'Vacancy has been deleted successfully',
    respond_title: 'Respond to vacancy',
    to_feed: 'In the Feed',
    feed_post: 'Dear colleagues! <br/>Our company has opened a vacancy. You can see it here - <a href="/vacancy/{id}" target="_blank">{header}</a><br/>',
    share: 'Copy link',
    vac_posted: 'The vacancy has been published to the Feed',
    link_copied: 'Link was copied',
    respond: {
      field1_label: 'Name of candidate',
      field2_label: 'Text of cover letter',
      att_cv: 'Attach CV',
      no_admins: 'No administrator was found',
    },
    template: `<h5>Job Description</h5><br/>

<h5>Requirements for the candidate</h5><br/>

<h5>Conditions</h5><br/>
`,
  },
  // Сервисы
  srv: {
    tabs: {
      all: 'Services',
      my: 'My requests',
      info: 'Information',
      steps: 'Steps',
      rights: 'Rights',
      table: 'Requests',
    },
    bp_table: {
      name: 'Process name',
      author: 'Author',
      performer: 'Performer',
      chief: 'Chief',
      start: 'Start date',
      step: 'Stage',
      deadline_step: 'Stage deadline',
      deadline: 'General deadline',
      status: 'Status',
      active: 'Active',
      finished: 'Finished',
      result: 'Status',
    },
    list: 'Business processes',
    my_orders: 'My orders',
    orders_to_me: 'Orders for approval',
    open_srv: 'Open order',
    title: 'Services for employees',
    total: 'Total amount',
    groups: 'Service groups',
    convert: 'Convert old',
    new: 'New business process',
    empty: 'Your company\'s corporate services will be here',
    new_bp: 'New business process',
    edit: 'Edit business process',
    edit_bp: 'Changing a business process',
    confirm_del: 'Delete business process?',
    types: {
      label: 'Service type',
      simple: 'Simple',
      simple_d: 'With one stage',
      many: 'Multi-stage',
      many_d: 'With several stages',
    },
    dept: 'Responsible department',
    no_dept: 'Department not specified',
    no_user: 'Employee not specified',
    no_date: 'Date not specified',
    no_data: 'Field not filled',
    days_limit_set: 'Set day limit',
    days_limit: 'Days limit',
    no_limit: 'Days limit has not been set.',
    for_all: 'Available to everyone',
    for_all_descr: 'Anyone can use this process',
    new_group: 'New service group',
    new_group_add: 'Add new service group',
    no: 'No services at now yet',
    not_found: 'Services not found',
    deleting: 'Deleting service',
    deleting_descr: 'Service «{name}» will be deleted',
    replace: 'Replace to another group',
    change: 'Change service groups',
    no_groups: 'No service groups',
    add_group: 'Add service group',
    del_group: 'Delete service group',
    group_deleted: 'Group was deleted',
    group_name: 'Group name',
    name: 'Service name',
    group: 'Service group',
    body: 'Service description',
    new_group_ph: 'Example: Reports',
    name_ph: 'Example: Tax reports',
    sg_empty_title: 'No service group name',
    sg_modal_title: 'New service group',
    sg_modal_edit: 'Edit service group',
    saved: 'Service was saved',
    select_group: 'Select section of services',
    select_empl: 'Select employee',
    step_employee: 'Stage responsible',
    fields: 'Process fields',
    steps: {
      start: 'Creating an order',
      name: 'Step name',
      fields: 'Step fields',
      fill_fields: 'Fill in the fields',
      new: 'New step',
      review: 'Review step',
      review_btn: 'I agree',
      new_field: 'Add field',
      field_title: 'Field title',
      field_value: 'Enter a value',
      field_select: 'Select a value',
      empty: 'No steps yet',
      errors: 'Errors in step settings',
      performer_settings: 'Settings for the person responsible for the step',
      performers: 'Step performers',
      no_performers: 'Step performers not specified',
      initiator: 'Request initiator',
      chief: 'Immediate supervisor',
      employee: 'Employee',
      employees: 'Several employees',
      set_me: 'Take on yourself',
      set_me_hint: 'Set yourself as the only performer and agree later',
      set_alone_succ: 'You are now the only performer of the stage',
      no_more5_empls: 'You cannot select more than 5 employees',
      not_more10: 'You cannot create more than 10 fields on the form',
      no_fields: 'There are no fields in the form',
      no_fieldname: 'One of the fields is not specified',
      no_formname: 'Form name is not specified',
      no_receivers: 'No recipients specified',
      default_name: 'Data to fill',
      no_selected: 'No step selected',
      save: 'Save step',
      del: 'Delete step',
      deleting: 'Deleting step',
      deleting_descr: 'Step "{name}" will be deleted',
      deleted: 'Step deleted',
      deleted_succ: 'Step deleted successfully',
      not_more5: 'You can\'t create more than 5 steps',
      saved: 'Step saved',
      saved_descr: 'Step data saved successfully',
      open_progress: 'Show steps',
      curr_step: 'Current step',
      fill_fields: 'Fill in the fields and approve or reject the current stage of the application',
      accept: 'Agree',
      decline: 'Reject',
      enter_reason: 'Specify the reason for rejection',
      invalid_action: 'Invalid action',
      decline_reason: 'No reason for rejection specified',
      accepted_succ: 'Step accepted',
      declined_succ: 'Step rejected',
      rejected: 'Step rejected for reason:',
      field_types: {
        string: 'String',
        text: 'Long text',
        number: 'Number',
        date: 'Date',
        period: 'Date period',
        select: 'Select from list',
        checkbox: 'Checkbox option',
        file: 'File',
        dept: 'Department',
        user: 'Select employee',
        users: 'Select employees',
        phone: 'Phone',
        email: 'Mail'
      },
    },
    form: {
      form: 'form',
      title: 'Form name',
      descr: 'Form description',
      new: 'New form',
      new_srv: 'New service form',
      no_forms: 'No forms yet',
      edit: 'Change form',
      fill: 'Fill form',
      errors: 'Errors while filling the form',
      srv_form: 'Service form \'{name}\'',
      new_field: 'Add field',
      field_title: 'Field title',
      field_type: 'Field type',
      field_mng: 'Managing',
      field_simple: 'Simple field',
      field_long: 'Long text',
      field_option: 'Option box',
      preview: 'Form preview',
      this_user: 'Author',
      max_str: 'You entered maximum amount of symbols - 50',      
    },
    err: {
      no_descr: 'Process description is not filled in',
      no_name: 'Process name is not specified',
      no_group: 'Service section is not selected',
      chief_404: 'Failed to determine the manager',
      del_error: 'The section contains services. Only an empty section can be deleted',
    },
    del_error: 'Group contains services. You can delete only empty group',
    start_bp: 'Create a order',
    cant_start: 'Cannot create a request, at least 2 steps are required',
    start_title: 'Create a new order',
    bp_started: 'Order created',
    bp_start_error: 'Failed to create order',
    del_error: 'Failed to delete group',
    succ: 'Completed successfully',
    rejected: 'Rejected',
    review: 'Please agree order is completed',
  },
  // Опросы
  polls: {
    title: 'Surveys',
    new: 'For example, an engagement survey',
    empty: 'There are no polls available yet, but you can create a poll to quickly collect the opinions of your colleagues',
    create: 'Create survey',
    name: 'Add a survey name',
    descr: 'Enter a description of the issue that employees will see',
    enter_descr: 'For example: Your opinion is important to us. Help us make the company even better!',
    deleting: 'Survey deleting',
    delete: 'Delete survey «{name}»?',
    deleted: 'Survey was deleted',
    q_amount: 'Questions in survey',
    q_short: 'quest.',
    you_can: 'You can answer a survey',
    enter_poll: 'Go to the survey',
    description: 'Description',
    ph_name: 'Enter a name for the survey',
    ph_descr: 'Add a survey description',
    editing: 'Editing survey',
    types: {
      opened: 'Open survey',
      opened_descr: 'Employees see each other\'s selected answers',
      opened_full: 'This poll is open, everyone can see the answers of other participants',
      closed: 'Closed',
      closed_descr: 'Employees see only their answers, administrators see all the data',
      closed_full: 'Poll responses are closed, you can see <b>only yours</b>. Admin can see all replies.',
      anonymous: 'Anonymous',
      anonymous_descr: 'Employees see only their own answers, the administrator sees anonymous answers',
      anonymous_full: 'This survey is <b>anonymous</b>, no one sees personal answers',
    },
    post_msg_created: 'New poll was created:',
    tbl: {
      main: {
        name: 'Survey title',
        author: 'Author',
        anonymous: 'Anon.',
        created: 'Crated at',
        type: 'Type',
        questions: 'Questions',
        answered: 'Answered',
        actions: 'Actions'
      }
    },
    qs: 'Questions',
    q_n: 'Question {n}',
    add_q: 'Add question',
    mandatory_q: 'Mandatory question',
    delete_conf: 'The question will be deleted, are you sure?',
    text_q: 'Question text',
    text_ph: 'For example, do you know what your employer expects from you?',
    kind_q: 'Type of question',
    answer: 'Answer',
    answer_n: 'Answer {n}',
    answers: 'Answers',
    my_answer: 'My answer',
    no_answer: 'You didn\'t answered this question',
    choose_range: 'Select value from 1 to {range}',
    you_choose: 'You selected: {val}',
    no_variants: 'No variants',
    add_ans: 'Add item to list',
    range: 'Range of score',
    loading_results: 'Results are loading',
    nothing: 'nothing',
    r_1_5: 'between 1 and 5',
    r_1_10: 'between 1 and 10',
    r_1_100: 'between 1 and 100',
    score: 'Score',
    ans_types: {
      short: 'Short test answer',
      long: 'Long text answer',
      range: 'Selecting number in range',
      list1: 'One item from list',
      list_m: 'Many items from list',
    },
    export: 'Export to Excel',
    passed: 'Survey is passed by',
    finished: 'The employee completed the survey',
    end: 'Finalize',
    next: 'Next question',
    back: 'Previous question',
    progress: 'You answered {num} questions from {count}',
    your_results: 'Your results',
    no_results: 'No results yet',
    results: 'Results',
    result_tabs: {
      main: 'General',
      table: 'Table',
      not_passed: 'Didn\'t pass the survey',
    },
    result_table: {
      name: 'full name',
      position: 'Position',
      dept: 'Department',
      isChief: 'Is chief',
      city: 'City',
      gender: 'Gender',
      workingTime: 'Work time',
    },
    filters: {
      gender: 'Gender',
      ages: 'Age',
      depts: 'Department',
      cities: 'City',
      workingTimes: 'Operating time',
      period: 'Completion period',
      chief: 'Is chief',
      positions: 'Position',
      active: 'Active Filters',
      execStart: 'Is completed after',
			execFin: 'Is completed before',
      ageOptions: {
        lt20: 'Less than 20 years',
        lt30: '20-30 years',
        lt40: '30-40 years',
        lt50: '40-50 years',
        gt50: '50+ years'
      },
      chiefOptions: {
        isChief: 'Is Chief',
        isNotChief: 'Not a Chief',
      },
      timesOptions: {
        lt3m: 'Less than 3 месяцев',
        lt1y: '3 months - 1 year',
        lt5y: '1-5 years',
        lt10y: '5-10 years',
        gt10y: '10+ years',
      },
    },
    questions_num: 'Questions',
    passed_num: 'Passed the survey',
    date: 'Date of creation',
    series: {
      amount: 'Amount',
    },
    result_hint: '{num} responses or {percent}%',
    del_filter: 'Reset filter',
    avg: 'Average result',
    err_excel: 'Error while exporting to Excel',
    err_name: 'Name is empty',
    have_passed: 'You have passed the survey earlier',
    is_passed: 'Passed',
    take: 'Take the survey',
    view_results: 'View results',
    loading: 'Survey is loading',
    grants_article_title: 'Grants for the article',
    empl_label: 'Select employees for the surveey',
    empl_select: 'Select employees',
    grants_title: 'Poll permitions',
    for_all: 'For all employees',
    closed: 'Closed results',
    anon_opt: 'Anonimous survey',
    actions: 'Actions with survey',
    no_data: 'No data for survey',
    saved_new: 'New survey is saved',
    saved: 'Survey is saved',
    thanks: 'Thank you for passing the survey!',
    return: 'Return to survey list',
    see_results: 'You can see results, if the survey is open for you',
    answers_title: 'Employee responses to the question',
    answered_users: 'Employees who gave the answer',
    who_answered: 'When asked <b>"{question}"</b> staff answered:',
    answer_choosen: 'Answer choice <b>"{question}"</b> chosen:',
    errors: {
      access: 'You do not have access to this survey',
      valid: 'Errors validating the survey',
      no_name: 'Title must be filled',
      no_more15ans: 'You cannot add more than 15 answers!',
      nothing: 'There is no questions, you cannot save survey',
      not_all: 'Not all questions are filled',
      no_mandatory: 'It is required at least one mandatory question must to be',
      no_ans: 'Some questions with list of answers have no items in list',
      ans_not_filled: 'Some questions have empty answers',
      excel: 'Error while generating Excel file',
      no_recipients: 'Recipients are not specified'
    },
  },
  // Чаты
  chat: {
    me: 'Me',
    no_rooms: 'No room exists',
    no_room_code: 'Room code is not specified',
    create_q: 'Create room?',
    no_msg: 'No message is here yet',
    enter_name: 'Enter chat name',
    create_private: 'Create private chat',
    room_added: 'Room was added',
    room_deleted: 'Room was deleted: {room}',
    del_room: 'Delete room',
    members: 'Members: {len}',
    error_rooms: 'Error while getting rooms',
    error_add_room: 'Error while adding room',
    error_del: 'Error of deleting',
    with: 'Chat with: ',
    add_empl: 'Add employee',
    del_empl: 'Delete employee',
    delete_all: 'Delete chat and all messages?',
    delete_room: 'Room and all messages will be deleted',
    you_added: 'You was added to chat room',
    you_removed: 'You was deleted from room: {name}',
    private_started: '{with} started chat with you',
    new_msg: 'New message in the room {name}',
  },
  // Структура
  struct: {
    title: 'Company structure',
    adm: 'Administrative',
    org: 'Organization',
    func: 'Functional',
    project: 'Project',
    main_org: 'General directorate',
    main_func: 'General department',
    main_prj: 'Main project',
    choose_type: 'View of company structures',
    hide_types: 'Hidden structures',
    expand_all: 'Expand all',
    t_diagram: 'As a chart diagram',
    t_tree: 'As tree-view list',
    select_dept: 'Select dept. for replacing to',
    select_dept_add: 'Select a department to add in',
    no_empl: 'No employees',
    add_empl: 'Add employee',
    new_name: 'Type new name',
    new_empl: 'Add employees',
    sel_mng: 'Select manager',
    del_empl: 'Delete employee',
    add_dept: 'Add department',
    move_dept: 'Move department',
    pin_dept: 'Pin department',
    unpin_dept: 'Unpin department',
    change_info: 'Change data',
    no_descr: 'No description for this department',
    replace_dept: 'Replace department',
    del_dept: 'Delete department',
    error_load: 'Structure data loading was failed',
    empl_added: 'Employee was added',
    empl_deleted: 'Employee was deleted',
    empl_not_deleted: 'Employee was not deleted',
    empl_deleted_full: '{name} was deleted from dept.',
    empl_added_full: 'Employee(s) was/were added to dept',
    empl_not_added: 'Employee was not added',
    del_empl_confirm: 'Confirm deleting employee',
    del_empl_content: 'Employee «{name}» will be deleted',
    err_no_company: 'Current company is not specified',
    mngr_set: 'Manager was set',
    no_manager: 'No manager in this department',
    mngr_set_full: 'Department manager is {name} now',
    err_manager: 'Error while selecting manager',
    saved: 'Structure was saved',
    err_save: 'Error while saving',
    err_not_found: 'Department was not found',
    err_no_empl: 'No employees are in this dept',
    add_empl_to_dept: 'Add employee to department',
    err_empl_in_dept: 'There are employees in this dept ({len})',
    err_dept_delete: 'Error while deleting dept',
    err_root_dept: 'You cannot delete root department',
    dept_del_confirm: 'Confirm deleting department',
    dept_del_content: 'Department «{name}» will be deleted',
    find_by_fullname: 'Search in full name',
    title_choose: 'Select new employees',
    title_del: 'Select employee to delete him',
    title_mngr: 'Select manager of department by pressing button in the list',
    title_new_dept: 'Creating a new department',
    title_edit_dept: 'Edit department',
    upper_dept: 'Upper department',
    please_name: 'Please, enter a name of department',
    ph_name: 'Marketing Department',
    ph_descr: 'We help potential customers learn about our product and become brand ambassadors',
    employees: 'Employees',
    manager: 'Manager',
    managers: 'Managers',
    employee: '{n} employee | {n} employees',
    employees_of: 'employees',
    open_list: 'Unfold the list',
    in_the_dept: 'in this department',
    include_sub: 'including all subordinates',
    err: {
      move_same: 'Department is the same as current',
      move_child: 'Cannot move to child department',
    },
  },
  // Не молчи
  quiet: {
    title: 'Don\'t be silent',
    user_mode: 'User mode',
    admin_mode: 'Editor mode',
    recipient: 'Assign staff to review requests',
    list_header: 'List of employees receiving requests',
    what: 'What is «Don\'t be silent»',
    no_service: 'The service is not available now...',
    no_srv_descr: 'Here you will informate about any violation of employees. Administrator will give an access to the service soon',
    recipients: 'The appeal will be considered',
    descr: 'This is confidential way to report about any trouble or violation\n of any employee: bribe, kickback, conflict of interest, misconduct',
    enter_name: 'Enter your name',
    label_name: 'Send as',
    anon: 'Anonimous',
    problem_label: 'Describe the essence of the complaint',
    problem: 'I have received information that',
    name: 'Name',
    empls: 'Employees who receive reports',
    sent: 'Message was sent',
    sent_full: 'Your letter has been sent. It will be considered in the near future. Thank you for your vigilance!',
    rules: {
      name: 'Name yourself or turn anonimous mode on',
      trouble: 'Add detailed description of your request',
    },
  },
  // Есть идея
  idea: {
    title: 'Propose an idea',
    recipient: 'Assign staff to review proposals',
    recipients: 'The idea will be considered by',
    no_service: 'Service not available yet...',
    no_srv_descr: 'In this service, you can offer ideas for improving processes in the company. The administrator will soon grant access to the service',
    list_header: 'List of employees receiving offers',
    descr: 'Here your employees can offer ideas for improving processes within the company. Assign staff to review proposals',
    fio: 'Enter your full name',
    empls: 'Employees who take proposals',
    no_receivers: 'Choose employees for taking proposals',
    fields: {
      name: {
        label: 'Author of the idea',
        hint: 'Surname and name',
        error: 'You need to enter your details',
      },
      describe: {
        label: 'Describe currently process',
        hint: 'I would like to share my thoughts on...',
        error: 'Enter what process needs to be improved',
      },
      disadv: {
        label: 'Improvement',
        hint: 'I think it can be improved...',
        error: 'You need to point out the flaw',
      },
      changes: {
        label: 'Changes',
        hint: 'I suggest changing...',
        error: 'Describe what needs to be done',
      },
      results: {
        label: 'What result can changes bring?',
        hint: 'The changes I propose will get...',
        error: 'Describe the intended outcome of the changes',
      },
    },
    got: 'Well done!',
    got_full: 'Your offer has been sent. Thank you<br/> for helping to make our common cause better!',
    sent: 'Proposal was sent',
    sent_descr: 'Your idea was sent to administrator\'s e-mail',
  },
  // Сотрудники
  empls: {
    title: 'Employee management',
    admin: 'Administrator',
    empl: 'Employee',
    mng_tab: 'All employees ({n})',
    rights_tab: 'Rights and roles',
    stat_tab: 'Mood statistic',
    other_tab: 'Other settings',
    notif_tab: 'Notifications',
    ui_tab: 'Interface',
    company_info_tab: 'Company data',
    f: {
      name: 'First name',
      mname: 'Middle name',
      lname: 'Last name',
      bd: 'Birth date',
      phone: 'Phone',
      lang: 'Language',
      int_phone: 'Internal phone',
      email: 'E-mail',
      position: 'Position',
			cabinet: 'Cabinet',
      department: 'Division',
      workingSince: 'In company since',
      pwd: 'Pasword',
      pwd2: 'Password repeat',
    },
    about: 'About',
    about2: 'Information about person',
    soc_nets: 'Social networks',
    for_hr: 'Personnel documents',
    hr: 'Information for HR',
    hr_files: 'Files for HR',
    hrm_data_title: 'Data from HRM base',
    show_del: 'Show deleted',
    new: 'New employee',
    greet_new: 'Greetings for new user',
    written: 'Employee was written',
    saved: 'Employee {fio} saved',
    created: 'Employee {fio} created',
    total: 'Amount of employees',
    incl_del: '(including %{len} deleted)',
    sent: 'Letters sended for',
    not_sent: 'No letter wasn\'t sent yet',
    pwd_reset: 'Reset password',
    reset_by_admin: 'Reset password (by admin)',
    reset: 'Reset',
    change: 'Change employee data',
    enter_name: 'Enter employee\'s name',
    choose_bd: 'Select date',
    enter_pwd: 'Enter password',
    enter_mname: 'Enter employee\'s middle name',
    enter_lname: 'Enter employee\'s last name',
    enter_email: 'Enter e-mail',
    enter_lang: 'Select language',
    will_del: 'will be deleted',
    fired: 'Fired',
    fireds: 'Fired',
    confirm_del: 'Confirm deleting employee',
    list_del : 'Deleting from the list of department employees',
    exclude: 'Excluding from all groups',
    blocked: 'Access will be blocked',
    pwd_admin_change: 'User <b>{user}</b> will have a new password set',
    enter_new_pwd: 'Enter new password',
    repeat_pwd: 'Repeat password',
    export_sendsay: 'Update e-mails',
    export_sendsay1: 'Export to Sendsay',
    export_process: 'Export employees to SendSay...',
    export_finish: 'Export is finiched..',
    export_err: 'Export finished with error: {err}',
    add_users: 'Fill out the list of employees to add',
    get_excel: 'Download Excel template',
    select_excel: 'Load list from Excel file',
    has_email_repeats: 'There are duplicate email addresses',
    user_exists: 'A user with this email already exists',
    success: 'Employee successfully registered',
    tbl: {
      main: {
        fio: 'Full name',
        email: 'e-mail',
        positions: 'Positions',
        phone: 'Phone',
        is_admin: 'Role',
        actions: 'Actions'
      }
    },
    rights: {
      wall: 'Publications on the wall',
      groups: 'Using groups',
      calendar: 'Using scheduler',
      knowl_base: 'Using knowlege base',
      vacancies: 'Using vacancies',
      services: 'Using services',
      structures: 'Using org. structures',
      no_silent: 'Using feature `Don\'t be silent`',
      ideas: 'Using feature \'Pick an idea\'',
      kb_files: 'Add new materials in knowlege base',
      vacations: 'Using vacations',
      vacations_edit: 'Edit vacations',
    },
    rights_change: 'Change rights',
    rights_add: 'Your rights have been added',
    rights_rem: 'Your rights have been revoked',
    rights_chng: {
      wall: "on a wall post",
      groups: "to use groups",
      profile_edit: 'to edit profile',
      calendar: "to use the calendar",
      events_create: 'to add events',
      task_tracker: 'to task tracker',
      desk_add: 'to add task boards',
      knowl_base: "to use the knowledge base",
      kb_files: "to add materials to the knowledge base",
      vacancies: "to use vacancies",
      services: "to use services",
      structures: "to use organizational structures",
      no_silent: "to use the 'Don't be silent' function",
      ideas: "to use the 'Suggest an idea' function",
      polls: 'to use polls',
      admin: "administrator",
      chat: "to chats",
      shop: 'to access the goods store',
      adaptation: 'for access to adaptation',
      vacations: 'to access vacations',
      vacations_edit: 'to edit vacations',
    },
    funcs_on: 'Feature enabled: ',
    funcs_off: 'Feature disabled: ',
    funcs: {
      wall: "Publications on the wall",
      groups: "Using groups",
      profile_edit: 'Editing profile',
      calendar: "Use calendar",
      events_create: 'Creating events',
      task_tracker: 'Task tracker',
      desk_add: 'Adding task boards',
      knowl_base: "Knowledge Base Usage",
      kb_files: "Adding materials to the knowledge base",
      vacancies: "Using vacancies",
      services: "Using services",
      structures: "Using organizational structures",
      no_silent: "Using the 'Don't be silent' feature",
      ideas: "Using the 'Suggest an Idea' feature",
      polls: 'Polls',
      chat: "Chat rooms",
      shop: 'Shop',
      adaptation: 'Adaptation',
      vacations: 'Using vacations',
      vacations_edit: 'Edit vacations',
    },
    err: {
      del: 'Error while deleting employee',
      del_admin: 'Administrator cannot be deleted',
      del_self: 'You cannot delete yourself',
      save: 'Error while saving employee',
      email: 'An employee with this email is already registered',
      role_set: 'Error while setting role',
      role_del: 'Error while removing role',
      role_change: 'Error while editing role',
      own: 'You cannot change your own role',
      data: 'Error in data',
      not_found: 'Employee not found',
      export: 'Error exporting to SendSay',
    },
    rules: {
      name: 'Name is required',
      last_name: 'Last name is required',
      symbols: 'Invalid symbols in the string',
      email: 'E-mail is required',
      corr_email: 'E-mail is invalid',
      pwd: 'Set a password',
      pwd_len: 'Password must be longer than 6 chars',
      pwd_confirm: 'Password needs to be confirmed',
      pwd2: 'Passwords don\'t match',
      max20: 'Maximum is 20 symbols',
    },
    restore_del: 'Restore deleted employee',
    empl_will_restore: 'Employee «{name}» will be restored',
    role_change: 'Changing role',
    role_add_to_all: 'Add role to all employees?',
    role_rem_from_all: 'Remove role from all employees?',
    role_succ: 'The role was set to all successfully',
    role_deleted: 'The role was removed from all successfully',
    remove_adm: 'Remove superuser rights from {fio}',
    make_adm: 'Set {fio} as superuser?',
    deleted: 'deleted',
    deleting: 'deleting',
    restored: 'restored',
    restoring: 'restoring',
    replaced: 'Changing in groups',
    del_as_admin: 'Delete as manager',
    empls_was: 'Employee {fio} was {del_type}',
    mood_period: 'Analysis period',
    two_weeks: 'Two weeks',
    one_week: 'One week',
    no_features: 'Client has no available features for setting roles',
    features: {
      wall: "Wall",
      address: "Address book",
      groups: "Groups",
      calendar: "Scheduler",
      profile_edit: 'Profile edit',
      knowl_base: "Knowlege base",
      events_create: 'Events creation',
      task_tracker: 'Task tracker',
      desk_add: 'Adding task boards',
      vacancies: "Vacansies",
      services: "Services",
      structures: "Org. structures",
      no_silent: "«Don't be silent»",
      polls: "Surveys",
      ideas: "«Pitch an idea»",
      kb_files: "Add to knowlege base",
      chat: "Chats",
      shop: "Shop",
      adaptation: 'Adaptation',
      vacations: 'Vacations',
      vacations_edit: 'Edit vacations',
      dark_theme: 'Dark theme',
    },
    forbid: 'Forbid',
    allow: 'Allow',
    confirmation: 'Confirmation',
    role_was_added: 'Role was added successfully',
    role_was_removed: 'Role was removed successfully',
    role_was_all_add: 'Role was added to all successfully',
    role_was_all_rem: 'Role was removed from all successfully',
    notif_settings: 'Company notification channels',
    notif_empl_settings: 'Activity of employee notification channels',
    tmpls: {
      code: 'Templage code',
      name: 'Name',
      title: 'Title',
      body: 'Short template text',
      full: 'Text of the full template',
      hint: 'Parameters',
    }
  },
  admin: {
    company_settings: 'Company Settings',
    logo_link: 'Link to the company logo',
    start_page: 'Start page when logging in',
    hrm_link: 'ZUP database address',
    load_empls: 'Load employees',
    empls_replace: 'Update profile data',
    empls_replace_warn: 'Attention! The data will be overwritten by data from HRM',
    load_struct: 'Load company structure',
    load_hrm_ok: 'Loading completed successfully',
    load_hrm_info: 'Loaded: {ok}, created {cr_len} new{created}',
    load_hrm_noemails: '. {no_emails} of employees without e-mail were found in the ZUP, fill out and repeat the upload',
    load_struct_hrm_ok: 'Company structure loaded',
    other: 'Other options',
    info: {
      inn: 'INN',
      inn_ph: 'Enter 10 digits',
      account: 'Current account',
      address: 'Legal address',
      phone: 'Phone of company office'
    },
    errors: {
      inn: 'Incorrect INN format',
      no_inn: 'To integrate with 1C, you must fill in the INN in the company data',
    },
    ui: {
      show_surnames: 'Show patronymic in address book, company structure and employee profile',
      show_client_title: 'Show the organization name in the portal header',
      article_files_list: 'Show files in articles as a list',
      exportUserHRMData: 'Export users with HRM data',
      editor_type: 'Use a lightweight editor',
      show_mood: 'Hide mood meter by default',
      links: {
        head: 'Useful links',
        title: 'Visibility of blocks with links',
        edit_title: 'Edit link',
        name_ph: 'Describe the link',
        link_ph: 'Enter link',
        article: 'Article',
        select_article: 'Select article',
        ext: 'External link',
        isBase: 'Knowledge Base',
        saved: 'Link block saved',
        no_links: 'There are no links',
        del_confirm: 'Are you sure to delete the link?',
        err: {
          title: 'No title specified',
          link: 'No link provided',
          link_url: 'Invalid link',
        },
      },
      colors: 'All colors'
    },
  },
  // Клиенты
  clients: {
    title: 'Clients management',
    new: 'New client',
    total: 'Total clients',
    hide: 'Hide blocked',
    active: 'Active',
    block: 'Block',
    clone: 'Clone client',
    del_client: 'Delete client',
    tbl: {
      main: {
        name: 'Name, code',
        phone: 'Phone',
        address: 'Address',
        users: 'Employees amount',
        access_status: 'Status',
        enter: 'Enter',
      },
    },
    err: {
      no_ws: 'Workspace name is not defined',
      no_name: 'New name is not defined',
      import_err: 'Failed to import customer data',
    },
    import: 'Import from backup',
    imported: 'Client imported successfully',
    new_ws_name: 'New name of workspace',
    new_name: 'New name of client',
    full_deleting: 'Full remove client',
    del_disclaimer: 'The client will wiped with all its data (posts, articles, etc.). \nThis operation cannot be turned back. \nConfirm this perform by selecting agree option',
    del_confirm: 'I confirm to wipe all data of client',
    sendsay_export: 'Export to SendSay',
    export_ok: 'Export to SendSay is finished',
    export_err: 'Export to SendSay was not correct: {err}',
    tabs: {
      data: 'Information',
      design: 'Design',
      features: 'Functionality',
      widgets: 'Widgets on the wall',
      other: 'Other rights',
      services: 'Maintenance',
    },
    name: 'Full name',
    name_ph: 'Enter full name',
    code: 'Id (workspace) of the client',
    code_ph: 'Enter short id',
    access: 'Access to the system',
    city: 'City',
    city_ph: 'Enter city',
    phone: 'Phone',
    address: 'Address',
    address_ph: 'Enter address',
    director: 'Chief',
    director_ph: 'Enter full name of chief',
    tg: 'Telegram channel',
    tg_ph: 'Enter channel name',
    space: 'Size of file storage',
    gb: 'GB',
    comments_label: 'Comment/notes',
    comments_ph: 'Enter comment',
    comments: 'Notes',
    logo: 'Logo of the company',
    header: 'Head of page (Height 160px, width from 1000px)',
    sidepanel: 'Side panel',
    backcolor: 'Color of backgr.',
    textcolor: 'Text color',
    font: 'Font',
    create_error: 'Customer creation failed',
    save_error: 'Error saving client',
    saved: 'Client saved',
    menu_customizing_title: 'Main menu titles and order',
    order_saved: 'Menu order has been saved',
    new_item_name: 'New name',
    export_data: 'Download all data',
    export: 'Download',
    maintenance: {
      heads: {
        convert: 'Converting heads to list',
        btn: 'Run',
        success: 'Conversion complete',
      }
    }
  },
  // Page and notification dialog
  notifs: {
    title: 'Notifications',
    tab: {
      all: 'All',
      blogs: 'Blogs',
      cal: 'Calendar',
      groups: 'Groups',
    },
    headers: {
      new_blog_post: 'New blog post',
      new_group_post: 'New group post',
      feed: 'In the feed',
      events: 'Events',
      new_user: 'New employee',
      bd: 'Birthday',
      new_group_member: 'New group member',
      event_del: 'Event was deleted',
      event_replaced: 'Event was moved',
    },
    errors: {
      no_tmpl_code: 'Template code not specified',
    },
    channels: {
      system: 'Browser',
      email: 'Emails',
      notif_center: 'Notification Center',
      tg: 'Telegram',
      msteams: 'MS Teams',
      slack: 'Slack',
    },
    not_set: 'Not set. Set in the company notification settings',
    no_notifs: 'No notification templates configured',
    tabs: {
      notifs: 'Notification settings',
      tempates: 'Notification templates',
    },
    channel_settings: 'Notification channel settings',
    trigger: 'Notification',
    check_read: 'Mark all as read',
    today: 'Today',
    yesterday: 'Yesterday',
    this_week: 'This week',
    earlier: 'Earlier',
    hide: 'Hide read',
    load_more: 'Load earlier notifications',
    nothing: 'No notifications yet',
    delete: 'The template will be completely removed',
    deleted: 'Template deleted successfully',
    only_for_empls: 'Notifications are only available for employee accounts',
    tmpl_groups: {
      posts: 'Posts (publications)',
      groups: 'Groups',
      events: 'Events and activities',
      services: 'Services and orders',
      polls: 'Polls and voting',
      shop: 'Shop',
      resp: 'Requests and responses',
      other: 'Other events',
      tasks: 'Task tracker',
      adapt: 'Adaptation',
    }
  },
  // Shop 
  shop: {
    title: 'Shop',
    add_reward: 'Add Reward',
    accrue: 'Accrue',
    nullify: {
      button: 'Reset the balance for everyone',
      alert: 'Are you sure you want to reset all the balances?',
      confirm: 'Reset',
    },
    tabs: {
      shop: 'Shop',
      stat: 'Statistics',
      achievements: 'Achievements',
      settings: 'Settings',
      balances: 'Balance',
    },
    sort: {
      cost_down: 'Descending price',
      cost_up: 'Ascending price',
      date: 'By date added',
      no: 'No sorting',
    },
    errors: {
      ach_saving: 'Error saving achievement',
      item_del: 'Product deletion failed',
      item_was_sent: 'Item cannot be deleted because there are sales',
      coins_not_canceled: 'Account or reward has not been cancelled'
    },
    no_settings: 'No store settings set',
    buy_btn: 'Buy',
    name: 'Shop name',
    curr_name: 'Currency name',
    curr_short: 'Currency shortened',
    managers: 'Managers',
    description: 'Description of the store and currency',
    curr_icon: 'Currency icon',
    ach_name: 'Achievement name',
    sett: {
      name: 'Shop name',
      name_ph: 'Enter store name to display in header',
      coin: 'Currency name',
      coin_ph: 'Enter the full name of the currency',
      code: 'Code',
      code_ph: 'UE',
      descr: 'Description of the store and currency',
      descr_ph: 'Enter a description of the items available to employees and other useful information'
    },
    stat_table_accr: {
      empl: 'Employee',
      achievements: 'Award',
      coins: 'Coins',
    },
    stat_table_purch: {
      empl: 'Employee',
      item: 'Purchase',
      cost: 'Cost',
    },
    stat_table_all: {
      num: "#",
      time: 'Operation date',
      empl: 'Employee',
      achievement: 'Reward',
      item: 'Purchase',
      comment: 'Description',
      amount: 'Amount',
    },
    balance_tbl: {
      user: 'Employee',
      balance: 'Balance',
      dept: 'Department',
      position: 'Position',
      achievements: 'Achievements',
    },
    saved: 'Shop data saved',
    ach: {
      header: 'Achievement',
      name: 'Achievement name',
      new_ph: 'To the best Buddy',
      pic: 'Add picture',
      max: 'Max rewards reached',
      no: 'Achievements not added yet',
      no_ach: 'No reward',
      add: 'Add achievement',
      change: 'Changing achievement',
      edit: 'Edit achievement',
      delete: 'Delete achievement',
      del_confirm: 'Do you want to delete the achievement from the database?',
      deleted: 'Achievement deleted',
      err_fill: 'Required fields not filled',
      name_ph: 'Enter the name of the award',
      descr_ph: 'His ward passed his probation period with flying colors',
    },
    auto_ach: {
      greetingPost: 'For gratitude',
      poll: 'For polling',
      profileCompletion: 'For completing your profile',
      birthDay: 'For the birthday',
      anniversary: 'For the anniversary',
      suggestIdea: 'For a good idea',
      title: 'Settings for automatic accrual of achievements and coins',
      empty: 'Select reward or coins',
    },
    periodic_ach: {
      workingPeriod: 'For working period',
      years: 'Years',
    },
    items: {
      header: 'Product',
      no: 'There are no products available in the store yet',
      add: 'Add product',
      new: 'Hoodie',
      edit: 'Edit product',
      del: 'Delete item',
      del_confirm: 'Do you want to delete an item?',
      deleted: 'Product in store was deleted',
      title: 'Product in store',
      name: 'Position name',
      descr: 'Item desriptions',
      cost: 'Cost',
      start_amount: 'Initial balance',
      amount: 'Balance',
      now_amount: 'Current balance',
      adding: 'Adding number of items',
      add_amount: 'Add amount',
      available: 'Available',
      pics: 'Add picture',
      size_tip: 'Recommended size 200x360 px',
      err_fill: 'All required fields of the heading have not been filled',
      err_cost: 'Error in price value',
      err_amount: 'Error in remainder value',
      not_available: 'Not available',
    },
    coins: {
      name: 'Coins',
      accrue: 'Accrue {name}',
      who: 'To',
      who_many: 'Advanced employee selection',
      achievement: 'Achievement',
      descr: 'Comment',
      public: 'Publish on wall',
      public2: 'A post mentioning the employee will appear on the wall',
      ph_who: 'Select who to give coins to',
      ph_achieve: 'Select achievement',
      cancel: 'Accrual will be cancelled',
      canceled: 'Accrual canceled successfully',
      err: {
        no_data: 'No amount or reward selected',
        amount: 'Not filled or invalid amount value',
        too_much: 'Too much or too little value',
        user: 'Coin recipient not filled',
        achieve: 'Achievement not completed',
        accrue: 'Error accruing coins',
        cancel: 'Error canceling accrual',
      },
      success: 'Accrual completed successfully',
      added: '{user} got {amount} coins',
    },
    buy: {
      title: 'Product purchase',
      you_buy: 'You are buying',
      you_have: 'You have',
      confirm: 'Purchase',
      ok: 'Purchase completed',
      bought: 'You bought {name}, congratulations!',
      error: 'Error buying',
      sa_cannot: 'Superadmin can\'t buy customer items',
      not_enough: 'Not enough coins to buy',
    },
    stats: {
      period: 'For period',
      dept: 'Department',
      tab_accr: 'Accruals',
      tab_purch: 'Purchases',
      all: 'Total',
      add: 'Addings',
      buy: 'Shopping',
    },
    post: {
      earn: 'earned',
      ach: 'achievement',
      and_coins: 'and {coins} {coin_name}',
    },
  },
  // Таск трекер
  task_tracker: {
    desks: {
      title: 'Task boards',
      no: 'No boards',
      add_title: 'Add board',
      add_lane: 'Add another column',
      add_task: 'Add card',
      sort_date: 'Sort by date',
      change_bg: 'Change background',
      info_title: 'About the board',
      fav: 'Favorites',
      fav_r: 'Favorite boards',
      my: 'My boards',
      own: 'I am the author',
      other: 'Other',
      oftens: 'Frequently visited',
      in_groups: 'Boards in groups',
      owner: 'Owner',
      admins: 'Administrators',
      kanban: 'Kanban',
      table: 'Table',
      name: 'Board title',
      filter: 'Filter',
      description: 'Description',
      active: 'Activity',
      status: 'Board status',
      delete_lane: 'Delete a column and all tasks',
      confirm_del_lane: 'Are you sure to delete the column and the tasks in it?',
      new_lane: 'New panel',
      deleted_lane: 'Panel deleted',
      desk_saved: 'Board information saved',
      copy_url: 'Copy link to board',
      copied_url: 'Board link copied',
      copy: 'Copy board',
      show_ended: 'Show completed',
      show_ended_tasks: 'Show completed cards',
      hide_ended: 'Hide completed',
      show_all_tasks: 'Show all cards',
      finish_confirm: 'Finish the board and all tasks on it?',
      finish: 'Finish the board',
      finished: 'Completed',
      restore: 'Restore',
      deleting: 'Deleting cards',
      all_finish: 'All tasks will also be completed',
      types: {
        ph: 'Task type',
        open: 'Open',
        active: 'Active',
        closed: 'Closed',
        hidden: 'Hidden',
      },
      table_cols: {
        name: 'Name',
        lane: 'Status',
        performer: 'Performers',
        author: 'Producer',
        plan: 'Date',
      },
      tasks_all: 'Total cards: {n}',
      tasks_finished: 'Completed: {n}',
      members_can: "Board members can:",
      can_view: 'View cards',
      can_comment: 'Comment on cards',
    },
    tasks: {
      lane_new: 'New',
      new: 'New task',
      open: 'Open task',
      created: 'Task created successfully',
      saved: 'Task has been saved',
      no_end_date: 'Indefinite',
      copied_url: 'Task link copied',
      copied_name: 'Task name copied',
      checklist_new_item: 'One more item',
      end_of_week: 'Until the end of the week',
      end_of_month: 'Until the end of the month',
      no_period: 'Plan date not specified',
      by_days: 'In days',
      by_period: 'Period',
      uploaded: 'File uploaded',
      no_users_selected: 'No employees selected',
      on_desk: 'On the board',
      priority: 'Priority',
      i_am: 'Set me',
      sel_lane: 'Select a new panel',
      conf_move: 'Confirm move',
      will_move: 'The task will be moved to the {to} section. Continue?',
      task_moved: 'Task moved from "{lane_from}" "{lane_to}"',
      task_moved_ok: 'Task moved',
      check_list: 'Check list',
      ch_done: 'Done: {n} of {count}',
      tags: 'Tags',
      sel_color: 'Select card color',
      color: 'Card color',
      sel_border: 'Choose a border color',
      border: 'Card outline',
      attachment: 'Attachment',
      time_title: 'Deadlines',
      time: 'Timing',
      hours: 'Hours accounting',
      plan_hours: 'Plan',
      real_hours: 'Actual',
      hours_hint: 'Costs:<br/>{real} out of {plan} h',
      performer: 'Performer',
      no_perf: 'No performer',
      actions: 'Actions',
      copy: 'Copy task',
      finish: 'Finish the task',
      finished: 'Task completed successfully',
      is_finished: 'Task is finished',
      del: 'Delete task',
      owner: 'Producer',
      responsibles: 'Responsible',
      coperformer: 'Co-executor',
      coperformers: 'Co-executors',
      observer: 'Observer',
      observers: 'Observers',
      created_at: 'Date created: {d}',
      upd_at: 'Updated at: {d}',
      del_confirm: 'Do you want to delete the task completely?',
      deleted: 'Task deleted successfully',
      confirm_del_file: 'Delete attachment?',
      confirm_priority: 'Change priority?',
      del_checklist: 'Delete checklist',
      for_adaptation: 'In adaptation',
      to_new_step: 'You have moved to the next step',
    },
  },
  // Adaptation
  adapt: {
    title: 'Adaptation',
    tracks: {
      add: 'Add track',
      copy: 'Copy track',
      delete: 'Delete track',
      del_confirm: 'When deleting, all data about past adaptations will be deleted',
      cant_delete: 'It is impossible to delete the track, there are unfinished employee adaptations',
      empty_title: 'Let\'s add the first adaptation track',
      empty: '',
      tabs: {
        all: 'All tracks',
        users: 'Participants',
        analytics: 'Analytics',
      },
      add_user: 'Add user',
      new_track_name_ph: 'Adaptation track for IT beginner',
      run: 'Run',
      stop: 'Stop',
      not_active: 'Track is not running',
      end: 'Track completed',
      errors: 'Track contains errors',
      manager: 'Manager',
      hr: 'HR manager',
      manager_ph: 'Select the person responsible for the module',
      settings: {
        dialog_title: 'Onboarding module settings',
        mentors: 'Mentors in the company',
        hrs: 'HR specialists',
        achievements: 'Awards',
        ach_title: 'Rewards given for length of service in the company',
        no_ach: 'No rewards for term of service',
        polls: 'Polls',
        polls_title: 'Polls to take after reaching your tenure with the company',
        days: 'Working days',
        coins: 'Coins',
        incorrect_ach: 'Incorrect settings - rewards or number of days are repeated',
        incorrect_polls: 'Incorrect settings - repeat polls or number of days',
        no_polls: 'No polls associated with work period',
      },
      go_to_usertrack: 'Go to employee progress',
      no_user_track: 'There is no active onboarding for this role.',
    },
    step: {
      step_ph: 'Enter step name',
      create_task: 'Create a task for this step',
      now: 'Immediately',
      set: {
        task: 'Task',
        achiev: 'Reward',
        article: 'Article or link',
        poll: 'Poll',
      },
      confirm_del_step: 'Delete step?',
      confirm_del: {
        article: 'Confirm deletion of article or link',
        poll: 'Confirm deleting the poll',
        achievement: 'Confirm deletion of rewards and achievements',
        task: 'Confirm deleting the task',
      },
      task: 'Task',
      daysBeforeRun: 'Days before run',
      achievement: 'Achievement',
      resource: 'Article or link',
      poll: 'Poll',
      task_todo: 'Task to complete the step',
      resume: 'Resume',
      back: 'Take a step back',
      task_expired: 'The task has expired ({days})',
      finished_time: 'Done at: {time}',
      notifEnabled: 'Notification on step start'
    },
    track: {
      tabs: {
        info: 'Basic',
        scenario: 'Scenario',
        users: 'Participants',
        analytics: 'Analytics',
        extra: 'Extra',
      },
      members_tbl: {
        name: 'Full name',
        begin: 'Start date',
        end: 'End date',
        mentor: 'Mentor',
        chief: 'Chief',
        progress: 'Progress',
        actions: 'Actions',
        not_started: 'Not started',
        not_ended: 'Not finished',
        fired: 'Fired',
        passed: 'Probation period has passed',
        delete: 'Delete adaptation'
      },
      err: {
        no_name: 'The track name is not filled in',
        no_new_user: 'No employee or mentor specified',
        user_on_track: 'The employee has already been added to this track',
        step_not_saved: 'Error saving step',
        required: 'Required fields are not filled in',
        users_equal: 'Mentor or HR cannot be the same as an onboarding employee',
        del: 'Error during deletion',
        cant_del_step: 'Cannot delete a step if adaptation is already in progress'
      },
      empls_title: 'Employees undergoing onboarding',
      no_onboarding: 'You are not currently onboarding with our company',
      back: 'Back to your adaptations',
      admin: 'Administrator',
      mentor: 'Mentor',
      chief: 'Head',
      mentors: 'Mentors',
      hr: 'HR manager',
      hrs: 'HR managers',
      novice: 'Newbie',
      users: 'Participants',
      time: 'Track expiration date',
      who: 'To whom',      
      no_stages: 'There are no stages',
      add_stage: 'Add stage',
      add_step: 'Add step',
      add_user: 'Add employee',
      stage_ph: 'Enter the name of the scenario stage',
      stage_added: 'Stage added',
      no_steps: 'No steps at this stage',
      step_added: 'Step added successfully',
      step_saved: 'Step saved successfully',
      add: 'Add new adaptation track',
      new_step: 'New step',
      progress: 'Progress',
      tasks: 'Tasks',
      starting_label: 'Create script steps starting from:',
      time_from_start: 'Start of adaptation',
      time_from_task: 'Prev. step',
      task_title: 'Task to complete',
      poll_title: 'Poll',
      resource_title: 'Resource for review',
      ach: 'Reward',
      article_link: 'Article or external resource',
      poll: 'Verification poll',
      select_ach: 'Select the reward for completing the stage',
      select_kb: 'Select from knowledge base',
      select_coins: 'Specify the number of coins',
      created: 'New track created successfully',
      new_user_ph: 'Select a new track member',
      user_added: 'Newbie has been successfully added to the track',
      no_mentor: 'No mentor',
      no_chief: 'No chief',
      no_empls: 'There are no active adaptations in which you are involved',
      no_empls_for_mentor: 'There are no employees for whom you are a mentor',
      no_empls_for_chief: 'There are no employees from your department',
      confirm_del_stage: 'Delete stage?',
      times: 'Duration of adaptation plan',
      no_time: 'Indefinite',
      end: 'End of the track',
      you_are: 'Your role: ',
      chief_of: 'You are the head of department "{dept}"',
      changed: 'Adaptation changed',
      changed_chief: 'Chief changed in adaptation',
      changed_mentor: 'The mentor has been changed in adaptation',
      changed_hr: 'HR changed in adaptation',
    },
  },
  vacations: {
    title: 'Vacation',
    title_sched: 'Vacation schedule',
    block: 'From {start} to {end}',
    add_vac: 'Add vacation',
    days_left: 'You have {days} vacation left',
    too_much: '. The number of days exceeds your balance',
    delete_confirm: 'Delete vacation from the schedule?', 
    deleted: 'Vacation deleted',
    past_date: 'Specify a date later than now',
    date_cross_vac: 'Error, vacation crosses with another vacation',
    days_not_enough: 'Not enough vacation balance',
    past_period_alert: 'Vacation will be created for past period'
  },
}
